import React from "react";
import { Helmet } from "react-helmet"

const Meta = () => (
    <Helmet>
    <meta charSet="utf-8" />
    <title>SAKAIzm | 女優・坂井真紀ファンサイト</title>
    <meta name="title" content="SAKAIzm" />
    <meta name="description" content="女優・坂井真紀さんのファンサイト。今後のテレビ・映画等出演情報や過去の出演作のほか、インタビュー記事へのリンク等を掲載。1999年より運営。" />
    <meta name="keywords" content="坂井真紀, さかいまき, Maki Sakai, ベイクオフ" />
    
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://sakaizm.com" />
    <meta property="og:title" content="SAKAIzm" />
    <meta property="og:description" content="女優・坂井真紀さんのファンサイト。今後のテレビ・映画等出演情報や過去の出演作のほか、インタビュー記事へのリンク等を掲載。1999年より運営。" />
    <meta property="og:locale" content="ja" />
    <meta property="og:image" content="https://sakaizm.com/og.png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:alt" content="SAKAIzm." />
    <meta property="fb:app_id" content="319840088143919" />
    
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://sakaizm.com" />
    <meta property="twitter:title" content="SAKAIzm" />
    <meta property="twitter:description" content="女優・坂井真紀さんのファンサイト。今後のテレビ・映画等出演情報や過去の出演作のほか、インタビュー記事へのリンク等を掲載。1999年より運営。" />
    <meta property="twitter:image" content="https://sakaizm.com/og.png" />
    <meta property="twitter:image:alt" content="SAKAIzm." />
    <meta name="facebook-domain-verification" content="xxh56ms3pip1qo4qtrxgpreg3cubez" />
    </Helmet>
)
export default Meta;