import React from "react";
import { Link } from "gatsby"

function Menu() {
  const [menuOpen, setMenuOpen] = React.useState(false);
  return (
    <>
      <div className="flex flex-wrap py-8">
        <div className="w-full">
          <nav className="relative flex flex-wrap items-center justify-between py-2 navbar-expand-lg bg-white rounded">
            <div className="container mx-auto flex flex-wrap items-center justify-between">
              <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                <p className="h1"><Link to="/">SAKAIzm<span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-400 to-yellow-400">.</span>
                </Link></p>
                <button
                  className="text-gray-700 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                  type="button"
                  aria-label="Open Menu"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                </button>
              </div>
              <div
                className={
                  "lg:flex flex-grow items-center" +
                  (menuOpen ? " flex" : " hidden")
                }
                id="example-navbar-info"
              >
                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                  <li className="nav-item">
                    <Link to="/news">
                    <button className="text-gray-700 border-b-4 border-pink-500 hover:bg-pink-100 hover:text-pink-600 focus:bg-pink-100 focus:text-pink-600 font-bold uppercase text-xs px-4 py-2 mr-2 mb-1 ease-linear transition-all duration-300" type="button">News</button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/web">
                    <button className="text-gray-700 border-b-4 border-red-400 hover:bg-red-100 hover:text-red-600 focus:bg-red-100 focus:text-red-600 font-bold uppercase text-xs px-4 py-2 mr-2 mb-1 mt-3 lg:mt-0 ease-linear transition-all duration-300" type="button">Around the Web</button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about">
                    <button className="text-gray-700 border-b-4 border-yellow-400 hover:bg-yellow-100 hover:text-yellow-600 focus:bg-yellow-100 focus:text-yellow-600 font-bold uppercase text-xs px-4 py-2 mr-2 mb-1 mt-3 lg:mt-0 ease-linear transition-all duration-300" type="button">About</button>
                    </Link>
                  </li>
                </ul>
              </div>
              <h1
                className={
                  "h3" +
                  (menuOpen ? " hidden" : " flex")
                }
              >
                女優・坂井真紀ファンサイト</h1>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

const Navbar = () => (
<div>
  <Menu></Menu>
</div>
)
export default Navbar;